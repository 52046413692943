<template>
  <v-container>
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <div class="alert-text mt-2 text-center">
          <h1 class="black--text"><b>Submit a Feature Article</b></h1>
        </div>
      </b-alert>
    </v-col>
    <v-card flat>
      <v-card-text>
        <v-form
            class="custom_form"
            ref="articleForm"
            v-model="validationRules.valid"
            lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">What is the title of the article you would like featured?
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field clearable
                            outlined
                            dense v-model="editedItem.article_title" :counter="100"
                            maxLength="100"
                            :rules="validationRules.titleRules"
                            label="Title of Article *"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">What category is most relevant to your planned article?
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                  clearable
                  outlined
                  dense
                  :items="articlesFilter"
                  v-model="editedItem.category"
                  item-text="title"
                  item-value="value"
                  label="Select a category *"
                  :rules="validationRules.selectRules"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card
                  color="#385F73"
                  dark
              >
                <v-card-title class="justify-center">Tell us where about the article you have in mind
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea clearable
                          outlined
                          dense v-model="editedItem.about" :counter="1000"
                          maxLength="1000"
                          :rules="validationRules.aboutRules"
                          label="Write here *"></v-textarea>
            </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!validationRules.valid" class="btn btn-primary" dark text @click="saveItem(editedItem)">
          Submit
        </v-btn>
      </v-card-actions>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>

import {mapState} from "vuex";
import {CLEAR_USER_ERRORS, SUBMIT_ARTICLE} from "@/core/services/store/users.module";
import Swal from "sweetalert2";

export default {
  name: "article-form",
  data() {
    return {
      editedItem: {},
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 100) || "Must be less than or equal to 100 characters"
        ],
        selectRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'This field is required';
            else return true;
          }
        ],
        aboutRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 10) || "Must be greater than or equal to 10 characters",
          v => (v && v.length <= 1000) || "Must be less than or equal to 1000 characters"
        ],
      },
    }
  },
  components: {},
  mounted() {
  },
  methods: {
    saveItem(item){
      if (this.$refs.articleForm.validate()) {
        this.$store.dispatch(CLEAR_USER_ERRORS);
        this.$store.dispatch(SUBMIT_ARTICLE, {data: item}).then((data) => {
          Swal.fire('', data._metadata.message, 'success').then(() => {
            window.location.reload();
          })
        })
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>
